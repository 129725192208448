@import '../node_modules/prismjs/themes/prism-tomorrow';
// @import './assets/scss/app.scss';

body,
html {
    font-family: 'Montserrat';
}

.basic-single {
    z-index: 2;
}

// //problem
// .modal-size {
//     width: 75% !important;
//     max-height: 90vh;
// }

.modal-size-m {
    margin-right: 15%;
    margin-left: 15%;
}

body {
    font-family: 'Montserrat', Helvetica, Arial, serif;
}

.cursor-pointer{
    cursor: pointer;
  }

  .word-break {
    // overflow-wrap: break-word;
    // overflow-wrap: anywhere;
  }

  .card-detail{
   
  }


  /* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
